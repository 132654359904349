<template>
  <div class="container flex-direction self-fixedWidth">
    <bread-crumb :jump-type="jumpType">科技成果</bread-crumb>

    <div class="flex flex-direction search_box">
      <div class="flex align-center search">
        <div class="self-end flex align-center search-input">
          <input ref="keyword" placeholder="请输入你需要的需求技术" value="" @keypress.enter="search" />
          <img style="cursor: pointer;" src="@/assets/img/header/search.png" @click.stop="search" />
        </div>
      </div>

      <div v-for="(item, index) in scienceTypeList" :key="'scienceTypeList' + index" class="flex flex-wrap">
        <div class="search-label">{{ item.name }}:</div>

        <div class="flex-sub flex align-center flex-wrap">
          <el-radio-group v-for="(ite, ind) in item.children" :key="'scienceType' + ind" v-model="radioCheckList[index]" class="scienceType-select" @change="radioChange">
            <el-radio-button :label="ite.id">{{ ite.name }}</el-radio-button>
          </el-radio-group>
        </div>
      </div>
    </div>

    <el-row :gutter="20" style="margin-top: 1rem;">
      <el-col :span="xsLeftV" :md="16">
        <div class="flex flex-direction achievements-box">
          <div v-for="(item, key) in datas.mdata.list" :key="'achievements-card' + key" class="achievements-card" @click.stop="navigation('detail', item)">
            <router-link :to="{ name: 'AchievementsDetail', query: { id: item.id, jump: 'achievements' } }" class="flex flex-direction">
              <div class="flex justify-between">
                <span class="text-bold">{{ item.title }}</span>
                <div class="text-bold" style="color: red; font-size: 0.875rem;">
                  <!-- <span v-if="item.price != '面议'">￥</span>
                  <span style="font-size: 1.25rem;">
                    <span>{{ item.price }}</span>
                    <span v-if="item.price != '面议'">元</span>
                  </span> -->
                  <span>
                    {{ item.price ? item.price + ' 元' : '面议' }}
                  </span>
                </div>
              </div>

              <div class="flex align-center" style="margin-top: 0.75rem;font-size: 0.875rem;color: #999;">
                <div class="flex align-center">
                  <i class="el-icon-time" style="font-size: 0.875rem; margin-right: 0.25rem;"></i>
                  {{ item.createTime.split(' ')[0] }}
                </div>
                <div class="flex align-center" style="margin-left: 3%;">
                  <i class="el-icon-location-outline" style="font-size: 0.875rem; margin-right: 0.25rem;"></i>
                  {{ item.address }}
                </div>
              </div>

              <!-- <span style="margin-top: 1rem; font-size: 0.875rem; line-height: 1.8; color: #666;">
                {{ item.synopsis.length > 120 ? item.synopsis.substring(0, 120) + '......' : item.synopsis }}
              </span> -->

              <div class="flex align-center justify-between" style="margin-top: 1.5rem; width: 80%; font-size: 0.875rem; color: #666;">
                <span>类型：{{ achTypeName(item.typeId) }} </span>
                <span>技术来源：{{ techSourceName(item.sourceId) }}</span>
                <span>所属领域：{{ item.fieldName }} </span>
                <span>阶段：{{ achStepName(item.phaseId) }} </span>
              </div>
            </router-link>
          </div>
        </div>

        <el-pagination
          class="pagination"
          background
          layout="prev, pager, next"
          :page-size="datas.mdata.limit"
          :page-count="datas.mdata.total"
          prev-text="上一页"
          next-text="下一页"
          :hide-on-single-page="true"
          :current-page="datas.mdata.page"
          @current-change="pageChange"
        ></el-pagination>
      </el-col>

      <el-col :span="xsRightV" :md="8">
        <div class="flex flex-direction">
          <div class="other-box">
            <span class="box_title">最新发布</span>
            <div class="flex flex-direction flex-wrap newest-message-box">
              <div v-for="(item, key) in datas.dataNewestScienceResult.list" :key="'newest-message' + key" @click.stop="navigation('detail', item)">
                <router-link :to="{ name: 'AchievementsDetail', query: { id: item.id, jump: 'achievements' } }" class="flex flex-direction">
                  <span class="box-span">{{ item.title }}</span>
                  <span class="box-span">{{ item.fieldName }}</span>
                </router-link>
              </div>
            </div>
          </div>
          <div class="other-box">
            <span class="box_title">最新技术专家</span>
            <div class="flex flex-direction newest-technician-box">
              <div v-for="(item, key) in datas.dataNewestTechnician.list" :key="'newest-technician' + key" @click.stop="navigation('technician', item)">
                <!-- <el-avatar :size="50" src=""></el-avatar> -->
                <router-link :to="{ name: 'TechnicianDetail', query: { id: item.id, jump: 'achievements' } }" class="flex align-center">
                  <div class="flex-sub flex flex-direction justify-between" style="margin-left: 0.25rem; height: 2.6rem;">
                    <span class="box-span">{{ item.name }}</span>
                    <span class="box-span">{{ item.level }}</span>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
          <div class="other-box">
            <span class="box_title">热门文章</span>
            <div class="flex flex-direction hotArticles-box">
              <div v-for="(item, key) in datas.dataMessageList.list" :key="'hotArticles' + key" @click.stop="navigation('hotArticles', item)">
                <router-link :to="{ name: 'InformationDetail', query: { id: item.id, jump: 'achievements' } }" class="flex align-center">
                  <el-avatar :size="50" :src="item.picture"></el-avatar>
                  <span class="flex-sub box-span">{{ item.name }}</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <PoupCode :img-url="'../../assets/img/qrcode/qrcode.png'" :center-dialog-visible="centerDialogVisible" @poupClose="centerDialogVisible = false" @poupOpen="centerDialogVisible = true" />

    <!-- 打开对话框 -->
    <!-- <el-dialog title="" :visible.sync="centerDialogVisible" width="44.5rem" style="text-align: center;">
      <el-image :src="require('@/assets/img/qrcode/qrcode.png')" fit="fill" style="width: 44.5rem;height: 30rem;"></el-image>
    </el-dialog> -->
  </div>
</template>

<script>
// import { request } from '@/utils/request';
import BreadCrumb from '@/components/BreadCrumb.vue';
import { requestScienceResultTypeList, requestScienceResultList, requestScienceExpertList, requestMessageList } from '@/api/index.js';
import { mapState, mapGetters } from 'vuex';
export default {
  name: 'NeedsDetail',
  components: { BreadCrumb },
  data() {
    return {
      jumpType: '',
      centerDialogVisible: false,
      // 搜索分类
      scienceTypeList: [
        { id: 1, name: '所属领域', dictName: 'user_common_field', children: [{ id: null, name: '全部', children: null }] },
        { id: 2, name: '成果类型', dictName: 'achievements_achievement_type', children: [{ id: null, name: '全部', children: null }] },
        { id: 3, name: '成果阶段', dictName: 'achievements_achievement_stage', children: [{ id: null, name: '全部', children: null }] },
        { id: 4, name: '技术来源', dictName: 'achievements_technology_source', children: [{ id: null, name: '全部', children: null }] }
      ],

      // 选项分类
      radioCheckList: [],

      // 领域类型
      positionDictName: 'user_common_field',
      positionList: [],

      // 成果阶段类型
      achStepDictName: 'achievements_achievement_stage',
      achStepTypeList: [],

      // 成果类型
      achTypeDictName: 'achievements_achievement_type',
      achTypeList: [],

      // 技术来源
      techDictName: 'achievements_technology_source',
      techList: [],

      // 获取所需数据列表
      datas: {
        // 获取左侧筛选列表
        mdata: {
          fieldId: '', // 行业
          phaseId: '', // 成熟度
          sourceId: '', // 技术来源
          typeId: '', // 技术类型
          total: 0,
          page: 1,
          limit: 12,
          name: '',
          list: []
        },
        // 获取最新发布列表
        dataNewestScienceResult: {
          page: 1,
          limit: 9,
          list: []
        },
        // 获取最新专家列表
        dataNewestTechnician: {
          limit: 7,
          page: 1,
          list: []
        },
        // 获取热门文章列表
        dataMessageList: {
          limit: 7,
          page: 1,
          list: []
        }
      }
    };
  },
  computed: {
    ...mapState({
      isMobile: state => state.isMobile
    }),
    ...mapGetters(['xsLeftV', 'xsRightV']),
    // 研究领域
    filedDictName() {
      return (fileValue) => {
        let findItem = {}
        findItem = this.positionList.find(
          (item) => parseInt(item.dictValue) === parseInt(fileValue)
        )
        if (findItem) {
          return findItem['dictLabel'] ? findItem['dictLabel'] : '无'
        }
        return '无'
      }
    },
    // 成果阶段
    achStepName() {
      return (fileValue) => {
        let findItem = {}
        findItem = this.achStepTypeList.find(
          (item) => parseInt(item.dictValue) === parseInt(fileValue)
        )
        return findItem && findItem['dictLabel'] ? findItem['dictLabel'] : '无'
      }
    },
    // 成果类型
    achTypeName() {
      return (fileValue) => {
        let findItem = {}
        findItem = this.achTypeList.find(
          (item) => parseInt(item.dictValue) === parseInt(fileValue)
        )

        return findItem && findItem['dictLabel'] ? findItem['dictLabel'] : '无'
      }
    },
    // 技术来源
    techSourceName() {
      return (fileValue) => {
        let findItem = {}
        findItem = this.techList.find(
          (item) => parseInt(item.dictValue) === parseInt(fileValue)
        )

        return findItem && findItem['dictLabel'] ? findItem['dictLabel'] : '无'
      }
    }
  },
  created() {
    this.initSelectList()
  },
  mounted() {
    this.init();
  },
  methods: {
    getFiledDicts() {
      const dictName = 'user_common_field'
      return this.getDictList(dictName)
    },
    getSourceDicts() {
      const dictName = 'achievements_technology_source'
      return this.getDictList(dictName)
    },
    getAchieveTypeDicts() {
      const dictName = 'achievements_achievement_type'
      return this.getDictList(dictName)
    },
    getAchieveStepDicts() {
      const dictName = 'achievements_achievement_stage'
      return this.getDictList(dictName)
    },
    initDictList() {
      const arr = JSON.parse(JSON.stringify(this.scienceTypeList))
      const promiseFunList = []
      const promiseFunNameList = ['getFiledDicts',
        'getAchieveTypeDicts',
        'getAchieveStepDicts',
        'getSourceDicts'
      ]
      for (let index = 0; index < promiseFunNameList.length; index++) {
        const element = promiseFunNameList[index]
        promiseFunList[index] = this[element]()
      }

      Promise.all(promiseFunList).then(res => {
        for (let index = 0; index < res.length; index++) {
          const element = res[index]
          if (element.code === 200) {
            let dataList = element.data
            dataList = this.manageDictList(dataList)
            arr[index].children = [...arr[index].children, ...dataList]
          }
        }
        this.scienceTypeList = arr
      })
    },
    manageDictList(dictList) {
      const tempObj = { id: '', name: '' }
      const resArr = dictList.map(item => {
        tempObj.id = parseInt(item.dictValue)
        tempObj.name = item.dictLabel
        return { ...item, ...tempObj }
      })
      return resArr
    },
    async init() {
      // 获取搜索选项列表
      await this.initDictList()
      this.scienceTypeList.forEach((value, key) => {
        value.children.forEach((v, k) => {
          if (k === 0) {
            this.radioCheckList[key] = v.id;
          }
        });
      });

      // requestScienceResultTypeList().then(res => {
      //   this.scienceTypeList = res.data;
      //   this.scienceTypeList.forEach((value, key) => {
      //     value.children.forEach((v, k) => {
      //       if (k === 0) {
      //         this.radioCheckList[key] = v.id;
      //       }
      //     });
      //   });
      // });

      // 技术成果
      requestScienceResultList(this.datas.dataNewestScienceResult).then(res => {
        console.log('res  ')
        console.log(res);
        this.datas.dataNewestScienceResult.list = res.data.data;
      });

      // 最新专家
      requestScienceExpertList(this.datas.dataNewestTechnician).then(res => {
        this.datas.dataNewestTechnician.list = res.data.data;
      });

      // 热门文章
      requestMessageList(this.datas.dataMessageList).then(res => {
        this.datas.dataMessageList.list = res.data.data;
      });

      this.request();
    },
    // 获取字典类型
    initSelectList() {
      this.getDictList(this.achStepDictName).then((res) => {
        if (res.code === 200) {
          this.achStepTypeList = res.data
        }
      })
      this.getDictList(this.positionDictName).then((res) => {
        if (res.code === 200) {
          this.positionList = res.data
        }
      })
      this.getDictList(this.achTypeDictName).then((res) => {
        if (res.code === 200) {
          this.achTypeList = res.data
        }
      })
      this.getDictList(this.techDictName).then((res) => {
        if (res.code === 200) {
          this.techList = res.data
        }
      })
    },
    request() {
      // 排除掉某个对象
      let { list, ...obj } = this.datas.mdata;

      requestScienceResultList(obj).then(res => {
        this.datas.mdata.total = res.data.total;
        this.datas.mdata.list = res.data.data;
      });
    },
    radioChange() {
      this.radioCheckList.forEach((value, key) => {
        switch (key) {
          case 0:
            this.datas.mdata.fieldId = value;
            break;
          case 1:
            this.datas.mdata.typeId = value;
            break;
          case 2:
            this.datas.mdata.phaseId = value;
            break;
          case 3:
            this.datas.mdata.sourceId = value;
            break;
          default:
            break;
        }
      });
      this.request();
    },
    pageChange(nowpage) {
      this.datas.mdata.page = nowpage;
      this.request();
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    search() {
      this.datas.mdata.name = this.$refs.keyword.value;
      this.request();
    },
    navigation(type, item) {
      switch (type) {
        case 'detail':
          this.$router.push({ name: 'AchievementsDetail', query: { id: item.id, jump: 'achievements' } });
          break;
        case 'technician':
          this.$router.push({ name: 'TechnicianDetail', query: { id: item.id, jump: 'achievements' } });
          break;
        case 'hotArticles':
          this.$router.push({ name: 'InformationDetail', query: { id: item.id, jump: 'achievements' } });
          break;
        default:
          break;
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.jumpType = to.query.jump;
    });
  }
};
</script>

<style lang="scss" scoped>
/* 去除默认样式 */
a {
  text-decoration: none;
  color: #333;
}
a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  color: #f08519;
  text-decoration: none;
}
a:active {
  text-decoration: none;
}

.container {
  margin: -1rem 0;
  padding-top: 1.2rem;
  padding-bottom: 1rem;
  min-height: 60vh;
  background-color: #f8f8f8;

  .search_box {
    margin-top: 1.5rem;
    width: 100%;
    height: auto;
    background-color: #fff;

    & > div {
      margin-top: 1rem;
      padding: 0 1.5rem 1rem;
      border-bottom: 1px solid #ebebeb;
    }

    & > div:last-child {
      border-bottom: 0;
    }

    .search {
      .search-input {
        display: inline-block;
        position: relative;

        input {
          padding: 0 2.375rem 0 0.625rem;
          width: 22.75rem;
          height: 1.875rem;
          background-color: #fff;
          border: 1px solid #f08519;
        }

        img {
          position: absolute;
          top: 50%;
          right: 0.625rem;
          transform: translateY(-50%);
          width: 1.375rem;
          height: 1.375rem;
        }
      }
    }

    .search-label {
      margin-top: 0.55rem;
      width: 3.75rem;
      font-size: 0.875rem;
      color: #999;
    }

    .scienceType-select {
      margin-left: 1.25rem;

      ::v-deep .el-radio-button__inner {
        color: #333;
        border: 0;
        background-color: #fff;
      }

      ::v-deep .el-radio-button__inner:hover,
      ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        color: #f08519;
      }
    }
  }

  .achievements-box {
    min-height: 1px;
    background-color: #fff;

    .achievements-card {
      padding: 1.5rem;
      border-bottom: 1px solid #ebebeb;
      cursor: pointer;
    }

    .achievements-card:last-child {
      border-bottom: 0;
    }
  }

  .other-box {
    position: relative;
    margin-bottom: 2rem;
    min-height: 10vh;

    .box_title {
      margin-left: 0.75rem;
      font-weight: bold;

      &::before {
        content: ' ';
        position: absolute;
        left: 0;
        top: 0;
        width: 0.25rem;
        height: 1.35rem;
        background-color: #f08519;
      }
    }

    .newest-message-box,
    .newest-technician-box,
    .hotArticles-box {
      margin-top: 1rem;
      padding: 0 0 1rem 1rem;
      min-height: 1px;
      width: 100%;
      background-color: #fff;

      & > div {
        margin-top: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #e8e8e8;
        cursor: pointer;

        .box-span {
          margin-left: 0.5rem;
          font-size: 0.875rem;
          font-weight: bold;
          color: #333;
        }

        .box-span:last-child {
          font-size: 0.75rem;
          color: #666;
          font-weight: 400;
        }
      }

      & > div:last-child {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }

    .hotArticles-box {
      .box-span {
        margin: 0 1rem 0 0.25rem;
        font-size: 0.875rem;
        line-height: 1.5;
        color: #333;
      }
    }
  }

  ::v-deep .el-pagination {
    display: inline-block;
    width: 100%;
    padding: 3rem 0 5rem;
    text-align: center;

    .btn-next,
    .btn-prev,
    .el-pager li {
      background-color: #fff !important;
      color: #666 !important;
      border: 1px solid #ebebeb;
    }

    .btn-next,
    .btn-prev {
      width: 4.375rem;
    }

    .el-pager li:not(.disabled).active {
      color: #fff !important;
      background-color: #f08519 !important;
    }

    .btn-next.disabled,
    .btn-next:disabled,
    .btn-prev.disabled,
    .btn-prev:disabled,
    .el-pager li.disabled {
      background-color: #ededed !important;
    }

    .el-pager li.active + li {
      border-left: auto;
    }
  }

  /* 修改弹窗样式 */
  ::v-deep .el-dialog {
    height: 30rem;

    .el-dialog__header {
      padding: 0;
    }

    .el-dialog__body {
      padding: 0;
    }
  }
}
</style>
